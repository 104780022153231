import { http, HttpResponse, passthrough, PathParams } from 'msw';

const segment = http.post('https://api.segment.io/v1/*', () =>
  HttpResponse.json({ success: true }),
);
const heap = http.get('https://heapanalytics.com/h', () => passthrough());

const google = http.post<PathParams>(
  'https://identitytoolkit.googleapis.com/v1/accounts:action',
  ({ params }) => {
    switch (params.action) {
      case ':signInWithPassword':
        return HttpResponse.json({
          kind: 'identitytoolkit#VerifyPasswordResponse',
          localId: 'XXXXX',
          email: 'john+doe@formbio.com',
          displayName: '',
          idToken: 'XXXXX',
          registered: true,
          refreshToken: 'XXXXX',
          expiresIn: '3600',
        });
      case ':lookup':
        return HttpResponse.json({
          kind: 'identitytoolkit#GetAccountInfoResponse',
          users: [
            {
              localId: 'XXXXX',
              email: 'john+doe@formbio.com',
              displayName: 'John Doe',
              photoUrl: null,
              passwordHash: 'XXXXXX',
              emailVerified: true,
              passwordUpdatedAt: 1657249439790,
              providerUserInfo: [
                {
                  providerId: 'password',
                  displayName: 'John Doe',
                  photoUrl: null,
                  email: 'john+doe@formbio.com',
                  rawId: 'john+doe@formbio.com',
                },
              ],
              validSince: '1657249439',
              lastLoginAt: '1662059571817',
              createdAt: '1657222050338',
              lastRefreshAt: '2022-09-02T05:08:42.473Z',
            },
          ],
        });
    }
  },
);

export const externalHandlers = [segment, heap, google];
