import { CreateCaasFlowRunParams, FlowRun, FlowRunType } from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(
  oid: string,
  pid: string,
  // TODO add more types for future flow runs
  type: FlowRunType,
): Promise<FlowRun[]> {
  return api
    .get<FlowRun[]>(`/orgs/${oid}/${pid}/flow-runs?type=${type}`)
    .then(({ data }) => data);
}

export function fetchOne({
  id,
  oid,
  pid,
}: {
  id: string;
  oid: string;
  pid: string;
}) {
  return api
    .get<FlowRun>(`/orgs/${oid}/${pid}/flow-run/${id}`)
    .then(({ data }) => data);
}

export function createCaasFlowRun({
  orgId,
  pid,
  analysisName,
  vectorFile,
  indication,
  vectorName,
  vectorType,
  gene,
}: CreateCaasFlowRunParams) {
  const body = new FormData();
  body.append('analysisName', analysisName);
  body.append('vectorFile', vectorFile);
  indication && body.append('indication', indication);
  body.append('vectorName', vectorName);
  body.append('vectorType', vectorType);
  gene && body.append('gene', gene);
  return api.post<{ id: string }>(`/orgs/${orgId}/${pid}/flow-run`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function flowRunVectorInsights({
  oid,
  pid,
  flowRunId,
}: {
  oid: string;
  pid: string;
  flowRunId: string;
}) {
  return api
    .post<{ json: string }>(
      `/orgs/${oid}/${pid}/flow-run/${flowRunId}/vector-insights`,
    )
    .then(({ data }) => data);
}
