import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { AxiosError, AxiosResponse } from 'axios';
import {
  createDatacoreEntity,
  fetchAllDatacoreEntities,
  fetchAllDatacoreTypes,
  fetchDatacoreEntity,
  fetchDatacoreType,
  fetchDatacoreTypeVersions,
  updateDatacoreEntity,
  updateDatacoreEntityRelationships,
} from '../../services/datacore';
import {
  DatacoreParams,
  DatacorePayload,
  DatacoreRelationshipDataPayload,
  DatacoreResponse,
  DatacoreResponseWithDataArray,
  DatacoreResponseWithDataObject,
} from '../../types';
import useInvalidation from '../useInvalidation';
const DEFAULT_ORG_ID = 'formbio';

/**
 * Generic way to fetch datacore entities
 */
export function useDatacoreEntities({
  orgId,
  pid,
  entityType,
  options,
  params,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  options?: Omit<
    UseQueryOptions<DatacoreResponseWithDataArray>,
    'queryKey' | 'queryFn'
  >;
  params?: DatacoreParams;
}) {
  return useQuery<DatacoreResponseWithDataArray>({
    queryKey: [entityType, orgId, pid],
    queryFn: () => fetchAllDatacoreEntities(orgId, pid, entityType, params),
    enabled: !!orgId && !!pid && !!entityType,
    ...options,
    // changes the type to fix a bug in the deserialization library
    select: (response) => ({
      ...response,
      data: response.data.map((item) => ({
        ...item,
        type: 'core',
      })),
    }),
  });
}

export function useDatacoreEntity({
  orgId,
  pid,
  entityType,
  entityId,
  options,
  params,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  entityId: string;
  options?: Omit<UseQueryOptions<DatacoreResponse>, 'queryKey' | 'queryFn'>;
  // TODO better param enforcement
  params?: DatacoreParams;
}) {
  return useQuery<DatacoreResponse>({
    queryKey: [entityType, entityId],
    queryFn: () =>
      fetchDatacoreEntity(orgId, pid, entityType, entityId, params),
    enabled: !!orgId && !!pid && !!entityType && !!entityId,
    ...options,
  });
}

export function useDatacoreEntityTypes({
  orgId = DEFAULT_ORG_ID,
  options,
}: {
  orgId?: string;
  options?: Omit<UseQueryOptions<DatacoreResponse>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<DatacoreResponse>({
    queryKey: [orgId],
    queryFn: () => fetchAllDatacoreTypes(orgId),
    enabled: !!orgId,
    ...options,
  });
}

export function useUpdateDatacoreEntity({
  orgId,
  pid,
  entityType,
  entityId,
  options,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  entityId: string;
  options?: UseMutationOptions<
    AxiosResponse<DatacoreResponse>,
    AxiosError<{ message: string }>,
    DatacorePayload
  >;
}) {
  const invalidate = useInvalidation(entityType);

  return useMutation<
    AxiosResponse<DatacoreResponse>,
    AxiosError<{ message: string }>,
    DatacorePayload
  >({
    mutationFn: (payload: DatacorePayload) =>
      updateDatacoreEntity({ orgId, pid, entityType, entityId, payload }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useDatacoreEntityType({
  orgId = DEFAULT_ORG_ID,
  entityTypeId,
  includeSchema = true,
  options,
}: {
  orgId?: string;
  entityTypeId: string;
  includeSchema?: boolean;
  options?: Omit<
    UseQueryOptions<DatacoreResponseWithDataObject>,
    'queryKey' | 'queryFn'
  >;
}) {
  const params: DatacoreParams | undefined = includeSchema
    ? { include: ['schema'] }
    : undefined;

  return useQuery<DatacoreResponseWithDataObject>({
    queryKey: [orgId, entityTypeId],
    queryFn: () => fetchDatacoreType(orgId, entityTypeId, params),
    enabled: !!orgId && !!entityTypeId,
    ...options,
  });
}

export function useDatacoreEntityTypeVersions({
  orgId = DEFAULT_ORG_ID,
  entityTypeId,
  includeSchema = true,
  options,
}: {
  orgId?: string;
  entityTypeId: string;
  includeSchema?: boolean;
  options?: Omit<
    UseQueryOptions<DatacoreResponseWithDataArray>,
    'queryKey' | 'queryFn'
  >;
}) {
  const params: DatacoreParams | undefined = includeSchema
    ? { include: ['schema'] }
    : undefined;

  return useQuery<DatacoreResponseWithDataArray>({
    queryKey: [orgId, entityTypeId, 'versions'],
    queryFn: () => fetchDatacoreTypeVersions(orgId, entityTypeId, params),
    enabled: !!orgId && !!entityTypeId,
    ...options,
  });
}

export function useCreateEntity({
  orgId,
  pid,
  entityType,
  options,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  options?: UseMutationOptions<
    DatacoreResponse,
    AxiosError<{ message: string }>,
    DatacorePayload
  >;
}) {
  const invalidate = useInvalidation(entityType);
  return useMutation<
    DatacoreResponse,
    AxiosError<{ message: string }>,
    DatacorePayload
  >({
    mutationFn: (data: DatacorePayload) =>
      createDatacoreEntity({
        orgId,
        pid,
        entityType,
        data,
      }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useUpdateEntityRelationshipData({
  orgId,
  pid,
  entityType,
  options,
}: {
  orgId: string;
  pid: string;
  entityType: string;
  options?: UseMutationOptions<
    DatacoreResponse,
    AxiosError<{ message: string }>,
    {
      entityId: string;
      payload: DatacoreRelationshipDataPayload;
    }
  >;
}) {
  const invalidate = useInvalidation(entityType);

  return useMutation<
    DatacoreResponse,
    AxiosError<{ message: string }>,
    {
      entityId: string;
      payload: DatacoreRelationshipDataPayload;
    }
  >({
    mutationFn: ({
      entityId,
      payload,
    }: {
      entityId: string;
      payload: DatacoreRelationshipDataPayload;
    }) =>
      updateDatacoreEntityRelationships({
        orgId,
        pid,
        entityType,
        entityId,
        payload,
      }),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
