import { formatMonthDayYear } from '@/utils/datetime';
import {
  SchemaCheckboxField,
  SchemaDirectoryField,
  SchemaDropdownField,
  SchemaFileField,
  SchemaTextField,
} from '@formbio/workflow-schema';

export const nameFieldConfig = (
  appName = 'appName',
  userName = 'userName',
): SchemaTextField => ({
  title: 'Name',
  type: 'string',
  required: true,
  pattern: '^[-a-zA-Z0-9 _,]*$',
  default: `${appName} Instance - ${userName} - ${formatMonthDayYear(
    new Date(),
  )}`,
});

export const defaultInputConfig = (
  hidden?: boolean,
  defaultVal?: string,
  format: 'file' | 'dir-path' = 'file',
  filetype?: string,
): SchemaFileField | SchemaDirectoryField => ({
  title: 'Input',
  type: 'string',
  format,
  default: defaultVal,
  required: !hidden,
  hidden,
  filetype,
});

export const ENABLE_GPU_FIELD_TITLE = 'Enable GPU';
export const GPU_SPEC_FIELD_TITLE = 'GPU Specification';
export const VM_SPEC_FIELD_TITLE = 'VM Specification';
export const SESSION_DURATION_FIELD_TITLE = 'Session Duration';
export const STORAGE_FIELD_TITLE = 'Storage';

export const defaultEnableGpuConfig = (
  hidden?: boolean,
): SchemaCheckboxField => ({
  title: ENABLE_GPU_FIELD_TITLE,
  type: 'boolean',
  default: false,
  required: false,
  hidden,
});

export const gpuSpecFieldConfig: SchemaDropdownField = {
  title: GPU_SPEC_FIELD_TITLE,
  type: 'string',
  format: 'enum',
  hidden: true,
  enum: [
    {
      id: 'nvidia-t4',
      name: 'NVIDIA T4',
    },
    {
      id: 'nvidia-l4',
      name: 'NVIDIA L4',
    },
    {
      id: 'nvidia-v100',
      name: 'NVIDIA V100',
    },
  ],
};

export const gpuCountFieldConfig: SchemaDropdownField = {
  title: 'GPU Count',
  type: 'string',
  format: 'enum',
  hidden: true,
  enum: [
    {
      id: 1,
      name: '1',
    },
    {
      id: 2,
      name: '2',
    },
    {
      id: 4,
      name: '4',
    },
  ],
};

export const vmSpecs = {
  xs: 'Extra Small - 4 vCPU - 16 GB',
  s: 'Small - 8 vCPU - 32 GB',
  m: 'Medium - 16 vCPU - 64 GB',
  l: 'Large - 32 vCPU - 128 GB',
  xl: 'Extra Large - 64 vCPU - 256 GB',
};

export function getVmSpecOptions(...args: Array<keyof typeof vmSpecs>) {
  return args.map(key => ({
    id: key,
    name: vmSpecs[key],
  }));
}

export const defaultVmSpecFieldConfig = (
  defaultVal?: string | number,
  hidden?: boolean,
): SchemaDropdownField => ({
  title: VM_SPEC_FIELD_TITLE,
  required: true,
  type: 'string',
  format: 'enum',
  default: defaultVal || 'xs',
  hidden: hidden,
  enum: getVmSpecOptions('xs', 's', 'm', 'l', 'xl'),
});

export const defaultStorageFieldConfig = (
  defaultVal?: string | number,
  hidden?: boolean,
): SchemaDropdownField => ({
  title: STORAGE_FIELD_TITLE,
  required: true,
  type: 'string',
  format: 'enum',
  default: defaultVal || '500G',
  hidden: hidden,
  enum: [
    {
      id: '500G',
      name: '500 GB',
    },
    {
      id: '1000G',
      name: '1 TB',
    },
    {
      id: '2000G',
      name: '2 TB',
    },
    {
      id: '3000G',
      name: '3 TB',
    },
  ],
});

export const defaultDurationFieldConfig = (
  defaultVal?: string | number,
  hidden?: boolean,
): SchemaDropdownField => ({
  title: SESSION_DURATION_FIELD_TITLE,
  required: true,
  type: 'string',
  format: 'enum',
  default: defaultVal || '2h',
  hidden: hidden,
  enum: [
    {
      id: '2h',
      name: '2 Hours',
    },
    {
      id: '4h',
      name: '4 Hours',
    },
    {
      id: '8h',
      name: '8 Hours',
    },
    {
      id: '12h',
      name: '12 Hours',
    },
    {
      id: '24h',
      name: '1 Day',
    },
    {
      id: '168h',
      name: '1 Week',
    },
  ],
});

export const defaultContainerTagFieldConfig = (
  multipleEnabled = false,
  tags = '',
): SchemaDropdownField => {
  const tagArray = tags.split(',').map(tag => tag.trim());
  return {
    title: 'Container Tag',
    type: 'string',
    format: 'enum',
    default: multipleEnabled && tagArray[0] ? tagArray[0] : '',
    hidden: !multipleEnabled,
    enum: tagArray.map(tag => ({ id: tag, name: tag })),
  };
};
