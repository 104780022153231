/* eslint-disable @typescript-eslint/no-empty-function */
import useSubdomainRedirect from '@/hooks/useSubdomainRedirect';
import { useOrgProjectLocalStorage } from '@/hooks/useOrgProjectLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { useAnalytics } from '@/utils/analytics';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type NavigationParams = {
  isOpen: boolean;
  toggleOpen: () => void;
  projectName: string | undefined;
  setProjectName: (_newProjectName: string | undefined) => void;
  organizationName: string | undefined;
  setOrganizationName: (_newOrganizationName: string | undefined) => void;
  openedParentIds: Set<string>;
  toggleParent: (_parentId: string) => void;
  setPageTitle: (_newPageTitle: string) => void;
  pageTitle: string | undefined;
};

const NavigationParamsContext = createContext<NavigationParams>({
  isOpen: true,
  toggleOpen: () => {},
  projectName: '',
  setProjectName: (_newProjectName: string | undefined) => {},
  organizationName: '',
  setOrganizationName: (_newOrganizationName: string | undefined) => {},
  openedParentIds: new Set<string>(),
  toggleParent: (_parentId: string) => {},
  setPageTitle: (_newPageTitle: string) => {},
  pageTitle: '',
});

export function NavigationParamsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const analytics = useAnalytics();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(true);
  const [openedParentIds, setOpenedParentIds] = useState<Set<string>>(
    new Set<string>(),
  );
  const [projectName, setProjectName] = useState<string>();
  const [organizationName, setOrganizationName] = useState<string>();
  const [pageTitle, setPageTitle] = useState<string>();
  const { saveOrgId, saveProjectId } = useOrgProjectLocalStorage();
  const { orgId, pid } = useUrlParams();
  // this will redirect users to assay or vectorflow pages when the subdomain is for one of those experiences
  useSubdomainRedirect();

  // reset org or project selected if navigating outside of their
  // respective scopes
  // save to localStorage otherwise

  useEffect(() => {
    if (!orgId) {
      setOrganizationName(undefined);
    } else {
      saveOrgId(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    if (!pid) {
      setProjectName(undefined);
    } else {
      saveProjectId(orgId, pid);
    }
  }, [pid]);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function handleProjectNameChange(newProjectName: string | undefined) {
    setProjectName(newProjectName);
  }

  function handleOrganizationChange(newOrganizationName: string | undefined) {
    setOrganizationName(newOrganizationName);
  }

  function toggleParent(parentId: string) {
    if (openedParentIds.has(parentId)) {
      setOpenedParentIds(openedParentIds => {
        openedParentIds.delete(parentId);
        return new Set(openedParentIds);
      });
    } else {
      setOpenedParentIds(
        openedParentIds => new Set(openedParentIds.add(parentId)),
      );
    }
  }

  const navigationContext: NavigationParams = {
    isOpen: isOpen,
    toggleOpen: toggleOpen,
    projectName,
    setProjectName: handleProjectNameChange,
    organizationName,
    setOrganizationName: handleOrganizationChange,
    toggleParent: toggleParent,
    openedParentIds: openedParentIds,
    setPageTitle,
    pageTitle,
  };

  useEffect(() => {
    if (pageTitle) {
      const queryParams = Object.keys(router.query).reduce(
        (acc: Record<string, unknown>, key) => {
          switch (key) {
            case 'pid':
              acc.project = router.query[key];
              break;
            case 'orgId':
              acc.organization = router.query[key];
              break;
            default:
              acc[key] = router.query[key];
              break;
          }
          return acc;
        },
        {},
      );

      analytics.page(pageTitle, queryParams);
    }
  }, [pageTitle]);

  return (
    <NavigationParamsContext.Provider value={navigationContext}>
      <Head>
        {/* title needs to be a JS string for now to avoid showing an HTML comment inline, https://github.com/formbio/colossal/pull/1141 */}
        <title>{pageTitle ? `${pageTitle} - Form Bio` : 'Form Bio'}</title>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>
      {children}
    </NavigationParamsContext.Provider>
  );
}

export const useNavigationContext = () => {
  return useContext(NavigationParamsContext);
};
