import { Organization } from './organization';
import { SeqVizJson } from './programs';
import { Project } from './project';
import { User } from './user';

export interface ExternalStripeOrder {
  id: string;
  amount_total: number; // in cents
  created: number;
  currency: string;
  customer_details: {
    email: string;
  };
  payment_status: string;
  payment_intent: {
    id: string;
  };
  metadata: {
    vectorId: string;
    formEnv: string;
    formbioOrg: string;
    formbioOrgId: string;
    formbioProject: string;
    formbioProjectId: string;
    userId: string;
  };
  total_details: {
    amount_discount: number;
    amount_tax: number;
  };
  livemode: boolean;
}

export interface StripeOrder {
  id: string;
  status: string;
  paymentIntentId?: string;
  createdAt: string;
  approvalStatus?: string;
  approvalStatusUpdatedAt?: string;
  approvalStatusUpdatedBy?: string;
  approvalStatusNotes?: string;
  isStripeTest?: boolean;
  isNoCostOrder?: boolean;
}

export interface ISAVector {
  id: string;
  projectId: string;
  name: string;
  vectorType: VectorType;
  source: string;
  createdAt: string;
  updatedAt: string;
  blastRunId?: string;
  mpRunId?: string;
  aucRunId?: string;
  cpgPath?: string;
  orfPath?: string;
  gcPath?: string;
  calcseqlenPath?: string;
  vectorViz?: SeqVizJson;
  metadata?: {
    [key: string]: string;
  };
  orders?: StripeOrder[];
  reportPDFRunId?: string;
  reportPDFPath?: string;
  indication?: string;
  gene?: string;
  datacoreEntityId?: string;
  typeSchemaVersionName?: string;
}

export interface AdminISAVector extends ISAVector {
  signedReportUrl?: string;
  externalOrder?: ExternalStripeOrder;
  user: User;
  project: Project;
  organization: Organization;
}

export type AdminOrderViewModel = {
  externalOrder: ExternalStripeOrder;
  internalOrder?: StripeOrder;
  isaVector?: ISAVector;
};

export type AdminRefund = {
  amount: number;
  created: number;
  id: string;
  payment_intent: {
    id: string;
  };
  reason: string;
  status: string;
};

export type AdminRefundViewModel = {
  refund: AdminRefund;
  internalOrder?: StripeOrder;
  isaVector?: ISAVector;
};

export enum VectorType {
  SingleStrand = 'ss',
  SelfComplementary = 'sc',
}

export interface CreateISAVectorParams {
  orgId: string;
  pid: string;
  name: string;
  vectorType: VectorType;
  file: File;
  indication?: string;
  gene?: string;
}

export interface UpdateISAVectorParams {
  orgId: string;
  pid: string;
  isaVectorId?: string;
  name: string;
  indication?: string;
  gene?: string;
}
export interface UpdateISAVectorMetadataParams {
  orgId: string;
  pid: string;
  vectorId: string;
  name: string;
  datacoreId: string;
  type: string;
  typeSchemaVersionName: string;
  metadata?: {
    [key: string]: string;
  };
}

export interface CreateVectorVADRParams {
  orgId: string;
  pid: string;
  vectorId: string;
  datacoreId: string;
  typeSchemaVersionName: string;
  vadrData: {
    [key: string]: string | number | boolean;
  };
}
export interface UpdateVectorVADRParams {
  orgId: string;
  pid: string;
  vectorId: string;
  datacoreId: string;
  vadrEntityId: string;
  typeSchemaVersionName: string;
  vadrData: {
    [key: string]: string | number | boolean;
  };
}

export enum VECTOR_STEPS {
  UPLOADING_FILE = 'Uploading and validating file',
  LAUNCHING_INSIGHTS = 'Launching insights',
  OPENING_RESULTS = 'Opening results',
}

export const VECTOR_STEPS_VALUES = {
  [VECTOR_STEPS.UPLOADING_FILE]: 30,
  [VECTOR_STEPS.LAUNCHING_INSIGHTS]: 60,
  [VECTOR_STEPS.OPENING_RESULTS]: 90,
};
