import { faker } from '@faker-js/faker';
import { VadrScores } from '@formbio/api/src/types/vadr-scores';

export const vadrScores: VadrScores = {
  heatmapConfig: {
    defaultSortScore: {
      scoreAttribute: 'overallCompositeScore',
      displayName: 'Overall',
      isComposite: true,
    },
    columnScores: [
      {
        scoreAttribute: 'percentFullGenomesScore',
        valueAttribute: 'percentFullGenomes',
        displayName: '% Full Genomes',
      },
      {
        scoreAttribute: 'immunotoxicityScore',
        valueAttribute: 'immunotoxicity',
        displayName: 'Immunotoxicity',
      },
    ],
  },
  vectorValues: Array.from({ length: 6 }).map(() => ({
    entityId: faker.string.uuid(),
    entityName: faker.word.words(2),
    percentFullGenomesScore: faker.number.float({
      min: 0,
      max: 1,
      precision: 0.01,
    }),
    immunotoxicityScore: faker.number.float({
      min: 0,
      max: 1,
      precision: 0.01,
    }),
    overallCompositeScore: faker.number.float({
      min: 0,
      max: 1,
      precision: 0.01,
    }),
  })),
};
