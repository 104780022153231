import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  createVectorEntityVADR,
  getVectorEntityVADR,
  getVectorEntityVadrColumnValues,
  updateVectorEntityVADR,
} from '../../services/vadr';
import {
  CreateVectorVADRParams,
  DATACORE_VADR_TYPE,
  DATACORE_VADR_VALUE_TYPE,
  DatacoreResponse,
  UpdateVectorVADRParams,
  VadrColumnValuesResponse,
} from '../../types';
import useInvalidation from '../useInvalidation';

export function useVectorVADRs({
  orgId,
  pid,
  vectorId,
  options,
}: {
  orgId: string;
  pid: string;
  vectorId: string;
  options?: Omit<UseQueryOptions<DatacoreResponse>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<DatacoreResponse>({
    queryKey: [DATACORE_VADR_VALUE_TYPE, orgId, pid, vectorId],
    queryFn: () => getVectorEntityVADR(orgId, pid, vectorId),
    enabled: !!orgId && !!pid,
    ...options,
  });
}

export function useVectorVadrColumnValues({
  orgId,
  pid,
  vectorId,
  vectorOrIndication,
  options,
}: {
  orgId: string;
  pid: string;
  vectorId: string;
  vectorOrIndication?: string;
  options?: Omit<
    UseQueryOptions<VadrColumnValuesResponse>,
    'queryKey' | 'queryFn'
  >;
}) {
  return useQuery<VadrColumnValuesResponse>({
    queryKey: [DATACORE_VADR_TYPE, orgId, pid, vectorId],
    queryFn: () =>
      getVectorEntityVadrColumnValues(
        orgId,
        pid,
        vectorId,
        vectorOrIndication || 'vector',
      ),
    enabled: !!orgId && !!pid,
    ...options,
  });
}

export function useCreateVectorVADR(
  options?: UseMutationOptions<
    // TODO: Update with correct response
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    CreateVectorVADRParams
  >,
) {
  const invalidate = useInvalidation(DATACORE_VADR_VALUE_TYPE);

  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    CreateVectorVADRParams
  >({
    mutationFn: (data: CreateVectorVADRParams) => {
      return createVectorEntityVADR(data.orgId, data.pid, data.datacoreId);
    },
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}

export function useUpdateVectorVADR(
  options?: UseMutationOptions<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateVectorVADRParams
  >,
) {
  const invalidate = useInvalidation(DATACORE_VADR_VALUE_TYPE);

  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateVectorVADRParams
  >({
    mutationFn: (data: UpdateVectorVADRParams) => {
      return updateVectorEntityVADR(
        data.orgId,
        data.pid,
        data.vadrEntityId,
        data.vadrData,
        // TOOD: Update when we actually update `typeSchemaVersionName`
        'v1.0.0',
      );
    },
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
