import {
  DATACORE_VADR_TYPE,
  DATACORE_VADR_VALUE_TYPE,
  DATACORE_VECTOR_TYPE,
  DatacoreResponse,
  VadrColumnValuesResponse,
} from '../../types';
import { api } from '../../utils/api';

export async function getVectorEntityVADR(
  oid: string,
  pid: string,
  datacoreVectorId: string,
) {
  return api
    .get(
      `/orgs/${oid}/${pid}/datacore/entities/${DATACORE_VECTOR_TYPE}/${datacoreVectorId}/relationships/childEntities?filter[type]=${DATACORE_VADR_VALUE_TYPE}&include=data`,
    )
    .then((res) => {
      return res.data as DatacoreResponse;
    });
}

export async function createVectorEntityVADR(
  oid: string,
  pid: string,
  datacoreVectorId: string,
) {
  return api
    .post(`/orgs/${oid}/${pid}/datacore/entities/${DATACORE_VADR_VALUE_TYPE}`, {
      data: {
        type: 'entities',
        attributes: {
          // TODO: We don't get any names from creating VADR entities, should we just keep it as DATACORE_VADR_TYPE?
          name: 'pat-vadr-take-1',
        },
        relationships: {
          parentEntity: {
            data: {
              type: 'entities',
              id: datacoreVectorId,
            },
          },
        },
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function updateVectorEntityVADR(
  oid: string,
  pid: string,
  vadrEntityId: string,
  vadrData: { [key: string]: string | number | boolean },
  version = 'v1.0.0',
) {
  return api
    .post(
      `/orgs/${oid}/${pid}/datacore/entities/${DATACORE_VADR_VALUE_TYPE}/${vadrEntityId}/relationships/data`,
      {
        data: {
          type: 'entityData',
          attributes: {
            data: {
              ...vadrData,
            },
            typeSchemaVersionName: version,
          },
        },
      },
    )
    .then((res) => {
      return res.data;
    });
}

export async function getVectorEntityVadrColumnValues(
  oid: string,
  pid: string,
  datacoreVectorOrIndicationId: string,
  datacoreVectorOrIndication = 'vector',
) {
  return api
    .get(
      `/orgs/${oid}/${pid}/${DATACORE_VADR_TYPE}/options?${datacoreVectorOrIndication}=${datacoreVectorOrIndicationId}`,
    )
    .then((res) => {
      return res.data as VadrColumnValuesResponse;
    });
}
