import { NavigableItem } from '@formbio/ui/types';
import { Theme, styled } from '@formbio/ui/components';
import { ListItemButton } from '@formbio/ui/components/List';
import NavListItemContent from '@formbio/ui/components/Button/NavListItemContent';
import NextMuiLink from '@formbio/ui/components/Link/NextMuiLink';
import { UrlObject } from 'url';
import CSS from 'csstype';

export interface NavItemStyleProps {
  backgroundColorHover?: CSS.Property.BackgroundColor;
  borderColorHover?: CSS.Property.BorderColor;
  borderTopBottomColorSelected?: CSS.Property.BorderLeft;
  borderLeftColorSelected?: CSS.Property.BorderLeft;
  iconColorSelected?: CSS.Property.Color;
  textColorDefault?: CSS.Property.Color;
  textColorSelected?: CSS.Property.Color;
}

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp(propName) {
    return propName !== 'getNavItemStyleProps';
  },
})<{
  component?: typeof NextMuiLink;
  nextHref?: UrlObject;
  getNavItemStyleProps: (theme: Theme) => NavItemStyleProps;
}>(({ theme, getNavItemStyleProps }) => {
  const navItemStyleProps = getNavItemStyleProps(theme);
  return {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '&.MuiListItemButton-root': {
      borderRadius: 0,
    },
    // controls the distance between icon and label
    '.MuiListItemIcon-root': {
      minWidth: '40px',
    },
    // icon size
    // TODO remove once all icons are using the phosphor package
    '.MuiListItemIcon-root .MuiSvgIcon-root': {
      fontSize: '1.1rem',
    },
    // on item selected or hovered
    '&.MuiListItemButton-root.Mui-selected,:hover': {
      backgroundColor: navItemStyleProps.backgroundColorHover,
      borderColor: navItemStyleProps.borderColorHover,
    },
    // on item selected: faint border + thicker secondary border on left side
    '&.MuiListItemButton-root.Mui-selected': {
      borderLeft: `2px solid ${navItemStyleProps.borderLeftColorSelected}`,
      // inner border only on top and bottom
      boxShadow: `inset 0px 11px 0px -10px ${navItemStyleProps.borderTopBottomColorSelected}, inset 0px -11px 0px -10px ${navItemStyleProps.borderTopBottomColorSelected}`,
    },
    '&.MuiListItemButton-root.leaf': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      // don't want the inner border on our leaves
      boxShadow: 'none',
    },
    '&.leaf:hover': {
      backgroundColor: theme.palette.primary[800],
    },
    '&.Mui-focusVisible': {
      // override inner box shadow with mui's default focus color no matter what
      boxShadow: `inset 0px 11px 0px -7px ${theme.palette.info[300]}, inset 0px -11px 0px -7px ${theme.palette.info[300]} !important`,
    },
  };
});

export default function NavListLinkItem<E>({
  selected = false,
  item,
  nested = false,
  onClick,
  getNavItemStyleProps,
}: {
  selected?: boolean;
  item: NavigableItem<E>;
  nested?: boolean;
  onClick?: () => void;
  getNavItemStyleProps: (theme: Theme) => NavItemStyleProps;
}) {
  return (
    <StyledListItemButton
      role='link'
      selected={selected}
      onClick={onClick}
      className={nested ? 'leaf' : undefined}
      component={NextMuiLink}
      nextHref={item.href}
      getNavItemStyleProps={getNavItemStyleProps}
      disabled={item.disabled}
    >
      <NavListItemContent
        item={item}
        nested={nested}
        selected={selected}
        getNavItemStyleProps={getNavItemStyleProps}
      />
    </StyledListItemButton>
  );
}
