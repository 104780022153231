import axios, {
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

export const api = axios.create({});

type InitParams = {
  getToken: () => Promise<string | undefined>;
  baseURL: string;
  onError?: (error: unknown) => void;
  isAdmin: boolean;
};

// These header strings are defined in services/edge/main.go
export const isAdminHeader = 'FormBio-Is-Admin';
export const webRouteHeader = 'FormBio-Web-Route';
export const commitShaHeader = 'FormBio-Commit-SHA';

export function init({ baseURL, getToken, onError, isAdmin }: InitParams) {
  api.defaults.baseURL = baseURL;

  api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const token = await getToken();
    if (isAdmin) {
      config.headers[isAdminHeader] = 'true';
    }
    if (window.location.href) {
      config.headers[webRouteHeader] = window.location.href;
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers[commitShaHeader] = process.env.NEXT_PUBLIC_COMMIT_SHA || '';

    return config;
  });

  if (onError) {
    api.interceptors.response.use(undefined, (error: unknown) => {
      onError(error);
      throw error;
    });
  }
}

export async function apiBatch(requests: Promise<AxiosResponse>[]) {
  return Promise.all(requests);
}

export type { AxiosRequestConfig, AxiosResponse };
